.cookies-bar {
    background-color: #e9edee;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}
.p-cookies {
    padding: 12px;
}
.cookies-bar button {
    cursor: pointer;
}