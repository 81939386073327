.banner-container  .dot {
    margin-top: 2px;
    width: 12px;
    height: 11px;
    margin-right: 6px;
    background-color: #F99537;
    border-radius: 50%;
    display: inline-block;
}
.banner-container  .dot.space {
    margin-right: 22px;
}

.banner-container  .dots {
    padding-right: 24px;
}

.banner-container {
    object-fit: fill;
    /*
    max-width: 500px;
    min-height: auto;
    width: 100%;
     */
    height: 200px;
}
.banner-container  .img-container {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 100%;
    padding: 24px;
}

.banner-container .container-1, .container-2 {
    width: 100%;
}

.banner-container  {
    object-fit: cover;
    background-size: cover;
    width: 100%;
    height: 340px;
    min-width: 600px;
    max-width: 800px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;

    margin: 4px 24px;
    padding: 12px;
}

.banner-container .p-title-banner {
    color: black;
    font-size: 1.085em;
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
}

.banner-container >.col {
    align-items: flex-start;
}

.banner-container .p-desc {
    color: white;
    font-size: 0.85em;
    font-weight: 500;
}

.banner-container-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.banner-container-row .container-1, .container-2 {
    width: fit-content;
}

.banner-container .custom-button {
    width: max-content;
}

/* MOBILE */

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 200px) {
    .banner-container-row {
        flex-wrap: wrap;
        zoom: 0.75;
    }
    .banner-container {
        height: fit-content;
    }
    .banner-container img {
        zoom: 0.6;
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 400px) {
    .banner-container-row {
        flex-wrap: wrap;
        zoom: 0.8;
    }
    .banner-container {
        height: fit-content;
        max-width: 100%;
        min-width: auto;
    }
    .banner-container img {
        zoom: 0.8;
        margin-top: -12px;
    }
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (min-width: 768px) {
    .banner-container-row {
        flex-wrap: nowrap;
        zoom: 0.85;
    }
    .banner-container {
        height: 280px;

    }
    .banner-container img {
        zoom: 0.85;
    }

}

/* Large devices (laptops/desktops, 992px and down) */
@media only screen and (min-width: 992px) {
    .banner-container-row {
        flex-wrap: nowrap;
        zoom: 0.85;
    }
    .banner-container {
        height: 280px;

    }
    .banner-container img {
        zoom: 1;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .banner-container-row {
        zoom: 1;
        flex-wrap: nowrap;
    }
    .banner-container {
        height: 280px;
    }
    .banner-container img {
        zoom: 0.85;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and down) */
@media only screen and (max-width: 1200px) {
    .banner-container-row {
        zoom: 0.85;
    }
    .banner-container {
        margin: 4px 8px;
    }
    .banner-container img {
        zoom: 0.75;
    }
}

/* Large devices (laptops/desktops, 992px and down) */
@media only screen and (max-width: 992px) {
    .banner-container img {
        zoom: 0.5;
    }
    .banner-container {
        margin: 4px 4px;
    }
    .banner-1 {
        justify-content: flex-end;
        padding-right: 14px;
    }
    .banner-2 {
        justify-content: flex-start;
        padding-left: 14px;
    }
}

/* Large devices (laptops/desktops, 992px and down) */
@media only screen and (max-width: 768px) {
    .banner-container img {
        zoom: 0.5;
    }
    .banner-container {
        margin: 4px 4px;
    }
    .banner-1 {
        justify-content: center;
        padding-right: 4px;
    }
    .banner-2 {
        justify-content: center;
        padding-right: 4px;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 400px) {
    .banner-container  .img-container {
        align-self: self-start;
        padding-top: 0px;
        zoom: 0.6;
    }
    .banner-container {
        max-width: 100%;
        min-width: auto;
    }
    .banner-container .p-desc {
        line-height: 12px;
    }
}



