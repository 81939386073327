.custom_map_container {
    display: flex;
    cursor: grab;
}
.custom_map_container:active {
    cursor: grabbing;
}
.custom_map {
}
.pigeon-attribution {
    display: none;
}