.search {
    background: white;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 14px;
    min-width: auto;
    max-width: 600px;
    height: 60px;
    width: 600px !important;
    font-weight: 300;
    padding-left: 30px;
}
.search-confirm {
    cursor: pointer;
    position: relative;
    margin-left: -39px;
    height: auto;
}
.search-confirm:hover {
    zoom: 1.07;
}
.search-form .input-label {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.search::placeholder {
    font-weight: 300;
    letter-spacing: 0.01em;
    color: #7C7A7A;
}
.search-form {
    padding: 24px 24px;
}