@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,300,800');

#root {
    height: 100vh;
}
html {
    scroll-behavior: smooth;
    /*
    -webkit-transform: translate3d(0, 0, 0);
     */
    -webkit-overflow-scrolling: touch;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #cfcfcf;
}

a, p, textarea, input, ul li {
    font-family: 'Open Sans', sans-serif;
}

@keyframes slide {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: -100% 0;
    }
    100% {
        background-position: 0 0;
    }
}

.bcg_slide {
    animation: slide 55s ease-in-out infinite;
}

.p-submit {
    color: white;
    font-size: 1.2em;
    font-weight: 400;
    margin: 0px;
    background-color: #29ABE2;
    border-radius: 20px;
    padding: 0px 24px 0px 24px;
    border: 1px solid #AFD1E8;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
}
p.large {
    font-size: 2.25em;
    height: 70px;
    border-radius: 25px;
    min-width: 170px;
    max-width: fit-content;
}
.p-large {
    color: black;
    font-size: 1.5em;
    margin: 0px;
    font-weight: 300;
}
.p-large:hover {
    font-weight: 400 !important;
}
.p-tab {
    color: black;
    font-size: 1.2em;
    margin: 0px;
    font-weight: 300;
}
.p-tab:hover {
    font-weight: 400 !important;
    margin-left: -1px;
    letter-spacing: -0.35px !important;
}
.p-header {
    color: white;
    font-size: 1.25em;
    font-weight: 400;
    margin: 0px;
}
.p-header.black {
    color: black;
    font-weight: 300;
}
.p-header.blue {
    color: #29ABE2;
    font-weight: 400;
}
.p-medium {
    color: black;
    font-size: 1em;
    margin: 0px;
    font-weight: 300;
}

.logged_in {
    margin: 0 12px;
}
.p-small {
    color: black;
    font-size: 0.85em;
    font-weight: 400;
    width: fit-content;
}
.p-landing {
    color: white;
    font-size: 3.25em;
    font-weight: bolder;
    margin: 0px;
    line-height: 1.6em;
}
.p-review {
    color: #29ABE2;
    font-size: 2.7em;
    font-weight: 800;
}

.p-desc, .p-info, .p-price {
    color: black;
    font-size: 0.78em;
    letter-spacing: 0.008em;
    font-weight: 300;
    width: fit-content;
    margin: 0;
    padding: 5px 0;
}
.p-desc.address {
    font-weight: 300;
    color: #8C898A;
    padding-bottom: 2px;
    border-bottom: 1px solid #8C898A;
    line-height: 0.9em;
}

.p-desc-list {

}

p.truncate {
    display: -webkit-box;
    -webkit-line-clamp: 7; /* Adjust this value to your desired number of lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 19px;
}
p.truncate.learn {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Adjust this value to your desired number of lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.p-info {
    font-weight: 400;
    padding: 0;
}
.p-label {
    color: black;
    font-size: 0.97em;
    font-weight: 300;
    width: fit-content;
    margin-bottom: 0.8em;
}
.p-xsmall {
    color: black;
    font-size: 0.8em;
    font-weight: 400;
    width: fit-content;
}
.p-price {
    text-decoration: line-through #29ABE2;
    font-weight: 400;
}
.p-discount-price {
    background: #29ABE2;
    color: white;
    padding: 4px 12px 4px 12px;
    margin-right: -12px;
    margin-left: 12px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.p-pageinfo {
    color: #7C7A7A;
    font-size: 0.8em;
    font-weight: 300;
    width: fit-content;
}
.p-xlarge {
    color: black;
    font-size: 2.5em;
    font-weight: 300;
    margin: 0px;
}
.p-text {
    color: black;
    font-size: 1em;
    font-weight: 300;
    margin: 0px;
}
.p-normal {
    color: black;
    font-size: 14px;
    font-weight: 300;
    margin: 0px;
}
.p-doc {
    cursor: pointer;
    padding: 12px 12px;
    max-width: 180px;
}
.p-doc:hover {
    text-decoration: underline #707070;
}
.icon-upload {

}
.row.docs {
    flex-wrap: wrap;
}
.upload-area {
    /*border: 2px solid #F0F0F0;*/
    border-radius: 12px;
    height: 140px;
    width: 140px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 36px;
    transition: all 0.2s;
}
.docs .upload-area {
    border: 2px solid #F0F0F0;
}
.upload-area:hover {
    border: 2px solid #C4C4C4;
}
.upload-area:hover img {
    transform: scale(1.03);
    transition: all 0.2s;
}
.upload-area img {
    object-fit: contain;
}
.upload-small {
    height: 100%;
    width: 100%;
    margin: 0;
}
.click {
    -webkit-user-select:none;
    -moz-user-select:none;
    user-select:none;
}
.click:hover {
    cursor: pointer;
    font-weight: 600;
    letter-spacing: -0.03rem;
}
.click::before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}
img.click:hover {
    cursor: pointer;
    transform: scale(1.04);
}

.click-light:hover {
    cursor: pointer;
    font-weight: 500;
    letter-spacing: -0.1px;
}
.click-light::before {
    display: block;
    content: attr(title);
    /*
    font-weight: 600;
     */
    height: 0;
    overflow: hidden;
    visibility: hidden;
}
.promo-text {
    z-index: 999;
    color: white;
    font-size: 3em;
    line-height: 1.5em;
    margin-left: 36px;
    font-weight: 900;
    height: 350px;
    max-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    text-align: center;
    text-shadow: 0px 0px 180px rgba(0,0,0,0.55);
    overflow: hidden;
}

/* HEADER & FOOTER */
.header_container, .footer_container, .footer_bcg {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
}
.header_container {
    height: 130px;
    margin-bottom: 22px;
}
.header, .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: inherit;
}
.header_logo {
    height: 46px;
    padding: 56px;
    max-width: 300px;
    max-height: 46px;
    object-fit: contain;
    min-width: 110px;
    cursor: pointer;
}
.header_inner_right, .footer_inner_right {
    padding-right: 56px;
    display: flex;
    align-items: center;
}
.header_user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.header_user:hover {
    cursor: pointer;
}
.header_user:hover p {
    font-weight: 600;
    letter-spacing: -0.25px;
}
.icon_user {
    height: 60px;
}
.logout {
    margin-right: 80px;
}
.logout:hover {
    font-weight: 600;
    cursor: pointer;
    letter-spacing: -0.25px;
}

.footer_container, .footer_bcg {
    height: 70px;
    bottom: 0;
    position: fixed;
}
.footer_bcg {
    bottom: -20px;
    filter: blur(30px);
    width: 110%;
    height: 90px;
    margin-left: -20px;
    opacity: 1;
}
.footer_bcg_container {
    position: absolute;
    bottom: 0;
    background-color: white;
    width: 120%;
    height: 80px;
    filter: blur(5px);

}

.footer {
    justify-content: flex-end;
}
.save {
    z-index: 2;
    border: solid 2px white;
    border-radius: 20px;
    padding: 4px 20px 4px 20px;
}

/* PAGE */

.page-container {

}
.page {
    padding-left: 56px;
    padding-right: 56px;
    padding-bottom: 80px;
}
.page.register {
    padding-bottom: 40px;
}

/* L10N */
.collapsible-header.l10n-options {
    right: 18px;
    position: absolute;
    top: 15px;
    cursor: pointer;
    zoom: 0.9;
}
.Collapsible__contentOuter {
    overflow: hidden;
    position: absolute;
    right: 17px;
    top: 56px;
}
.collapsible-header.l10n-options > svg {
    zoom: 0.75;
    padding-left: 5px;
    fill: white;
}
.l10n-options-content {
    zoom: 0.8;
    cursor: pointer;
}

.Collapsible__contentInner .country-label-container {
    padding-top: 3px;
}

/* FORM COLUMNS */

.form_col {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.form-row form > label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
}
.form-row form > label > p {
    margin-right: 40px;
    min-width: 14vw;
    max-width: 14vw;
}
.form-row form > label > input {
    width: 500px;
    margin-right: 20px;
    background: white;
}

/* SCROLLABLE TABS */
.tabs-horizontal {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
    overflow-x: scroll;
    overflow-y: visible;
    min-width: 100%;
    max-width: 100vw;
  /*  max-height: 100px; */
    margin-top: 10px;
    margin-bottom: 12px;
    position: relative;
    /*padding-left: 27px !important;*/

    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
}
.select-default__menu {
    position: absolute;
}

.tabs-horizontal .tab-outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tabs-horizontal .category-container {
    min-height: 60px;
    max-height: 60px;
    min-width: 100px;
    max-width: 100px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-items: center;
    align-items: center;
}
.template-0 .tabs-horizontal .category-container {
    background-color: #333F48;
}
.template-1 .tabs-horizontal .category-container {
    background-color: rgba(255, 255, 255, 0.62);
}
.tabs-horizontal .category-container > img {
    object-fit: contain;
    height: 34px;
    width: 100%;
}
.tabs-horizontal > *:not(:last-child) {
    padding-right: 50px;
}
.tabs-horizontal::-webkit-scrollbar {
    display: none;
}


#selected_true.tab-outer-container > p {
    border-bottom: 2px solid #29ABE2;
    font-weight: 400;
}

.following_container {

}
.following-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    padding-left: 70px;
}
.following-row > .row_inner_left > p {
    margin-left: 20px;
}
.row_inner_left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.row_inner_left .dot, .lecturers-col .dot {
    height: 60px;
    width: 60px;
    background-color: #cfcfcf;
}

/* FILTERS */

.filters .custom-dropdown .select-default {
    max-width: 220px;
}

/* DATE RANGE PICKER*/

.react-daterange-picker__wrapper {
    border: none !important;
    margin-top: 12px;
}

.react-daterange-picker__wrapper .react-daterange-picker__calendar-button.react-daterange-picker__button:hover svg {
    stroke: #29ABE2 !important;
}
.react-daterange-picker__wrapper  .react-daterange-picker__clear-button.react-daterange-picker__button:hover svg {
    stroke: red !important;
}


/* CATEGORIES */

.categories_container {
    display: flex;
    flex-direction: row;
    padding-left: 13px;
    overflow-x: auto;

}
.cat-row {
    padding: 0 60px 12px 60px;
    border-right: 1px solid #AFD1E8;
    border-radius: 1px;
}
.cat-row > p {
    margin-top: 0;
    margin-bottom: 1.25em;
    font-weight: 600;
}
.child-row {
    margin-left: 16px
}

.categories_container .cat-row {
    min-width: 190px;
}
.categories_container .cat-row p {
    max-width: 180px;
}
/*FOLLOWING COURSES */

.courses_container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding-bottom: 20px;
    margin-bottom: 48px;
}
.scroll::-webkit-scrollbar {
    padding-bottom: 24px;
    height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
    background: #8C898A;
    border-radius: 20px;
    height: 20px;
}

.scroll::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
    height: 20px;
}
.course-col {
    width: min-content;
    margin: 12px;
    box-shadow: 0px 2px 5px rgb(208 208 208);
    border-radius: 37px;
    cursor: pointer;
}
.course-col-top img {
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
    box-shadow: 0px 3px 5px rgb(208 208 208);

}
.course-col-bottom {
    padding: 12px;
    padding-top: 0;
}
.course-col-bottom .p-small {
    margin: 0
}
.course-col-bottom .p-large {
    font-weight: 400;
}
.course-col-bottom-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /*max-width: 77%;
     */
    max-width: 95%;
    padding-top: 0px;
}
.course-col-bottom-inner-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.course-col-bottom-inner-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 0;
}
.course-col-bottom-inner-row.price {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    padding: 0;
}
.course-col-bottom:hover .p-large {
    /*font-weight: 300 !important;
     */
}
.course-col-bottom .p-desc.desc {
    padding-top: 8px;
    margin-bottom: 8px;
}
img.icon-info {
    padding-right: 5px;
}

.icon-heart-container {
    position: relative;
    width: 264px;
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
    margin-bottom: -40px
}
.icon-heart-container img {
    position: relative;
    right: 0;
}
img.icon-label {
    box-shadow: none;
    border-radius: 0;
}
img.icon-label.accommodation {
    justify-self: flex-end;
}
.label-accommodation-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: -36px;
    padding-right: 12px;
}

/* RESERVATIONS */
.reservation-col {
    min-width: 650px;
    margin: 12px;
    margin-right: 24px;
    box-shadow: 0px 2px 5px rgb(208 208 208);
    border-radius: 37px;
}
.reservation-col .course-col-bottom {
    display: flex;
    padding: 0 24px;
}
.reservation-col .course-col-top img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}
.reservation-col .course-col-bottom-inner-row.address {
    align-items: flex-end;
    width: fit-content;
    padding-bottom: 12px;
}
.reservation-col-date {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: fit-content;
    width: 64%;
}
.reservation-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: fit-content;
}
.reservation-row > p {
    min-width: 25%;
}
.p-info.bold {
    font-weight: 600;
}
p.reservation-number {
    font-weight: 300 !important;
}
p.reservation-number span {
    font-weight: 400;
}
p.reservation-number:hover {
    font-weight: 300 !important;
}
p.reservation-number.blue {
    color: #29ABE2;
}
.reservation-bot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding: 20px 0;
}

.reservation-col-left {
    width: 100%;

}
.reservation-col-left .p-header {
    padding: 12px 0;
}
.reservation-col-left .p-info.bold {
    padding: 14px 0;
    padding-top: 20px;
}
.reservation-col-left .reservation-number {
    padding: 0;
}
.reservation-col-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -63px;
}
.reservation-col-right .contact-us-button {
    margin: 12px 0;
}

.contact-us-button {
    background-color: #29ABE2;
    padding: 0px 20px 0px 20px;
    width: fit-content;
    cursor: pointer;
}
.contact-us-button span {
    font-size: 0.75em;
    padding-right: 8px;
    font-weight: 400;
}
.contact-us-button:hover, .follow.p-submit:hover {
    transform: scale(1.015);
}



.reservation-col-left .p-desc.address {
    margin-left: 6px;
}
.calendar, .address {
    cursor: pointer;
}

.archive img {
    filter: saturate(0%) brightness(96%);
}
.reservation-col-left:first-child {
    padding-top: 12px;
}

/* MAIN PAGE */
.bcg_layer {
    height: 405px;
    background-repeat: repeat-x;
    width: 100%;
    position: absolute;
    left: 0;

    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

/* PROVIDER PAGE */
.hamburger_menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    cursor: pointer;
    position: absolute;
    right: 56px;
    margin-top: -64px;
}
.provider-info-container {
    padding-left: 24px;
    padding-top: 1px;
}
.provider-info-container p span {
    text-decoration: underline #29ABE2;
}
.provider-info-container.course-info p span {
    text-decoration: unset;
}
.provider-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.p-pageinfo {
    padding-bottom: 24px;
}

.provider-row .row_inner_right span {
    text-transform: uppercase;
}
.provider-info-container .poi {
    padding: 24px 0;
}

.provider-info-container .poi >div {
    width: fit-content;
}
.provider-info-container .p-info, .provider-row.facts p.del {
    color: #8C898A;
}
.provider-row.facts p {
    padding: 0 12px;
}
.provider-row.facts {
    margin-top: -4px;
}

.courses_container .course-col-top img {
    max-height: 193px;
    object-fit: cover;
}

.follow {
    height: 34px;
    font-size: 1em;
    border-radius: 7px;
    padding: 0 12px 0 12px;
    cursor: pointer;
    margin-left: 24px;
    margin-bottom: -4px;
}
.follow span {
    text-transform: capitalize !important;
}
.follow.p-submit {
    margin-bottom: 0;
    height: auto;
}


.provider-row.languages {
    padding: 24px;
    padding-left: 12px;
}
.provider-row.languages span {
    padding-left: 8px;
}
.gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
}
.gallery img {
    /*width: 100%;*/
    height: 600px;
    max-height: 600px;
    object-fit: contain;
    border-radius: 12px;
}
.gallery_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
    overflow-x: auto;
    max-width: 40vw;
    margin-left: 23%;
    padding-left: 150px;
}
.gallery_img_cont {
    width: min-content;
    box-shadow: 0px 10px 10px rgb(208 208 208);
    border-radius: 30px;
    cursor: pointer;
    margin: 12px;
    height: 77px;
}
.provider-row.desc p {
    padding: 60px 12%;
}

.lecturers-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding-bottom: 60px;
    /*
    flex-wrap: wrap; FOR MOBILE & TABLET
     */
}
.lecturers-item {
    display: flex;
    flex-direction: row;
    min-width: 300px;
    margin-right: 24px;
}
.lecturers-col {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.lecturer-desc {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.19);
    border-radius: 22px;
    padding: 12px;
    width: 100%;
    min-height: 100%;
    height: max-content;
    height: auto;
}
.p-header.black.lecturer {
    font-weight: 400;
    padding: 24px;
    width: max-content;
}

.reviews-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    justify-content: space-evenly;
}

.reviews-container .review-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.reviews-container .review-bar-container {
    display: flex;
    flex-direction: row;
    width: 70%;
    align-items: center;
}

.reviews-container .review-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    justify-content: center;
    max-width: 600px;
}
.review-bar {
    width: 100%;
    background: #D9D9D9;
    border-radius: 5px;
    height: 12px;
    margin: 8px 0;
    min-width: 100%;
}
.review-fill {
    background: #F99537;
    border-radius: 5px;
    height: 12px;
}
.p-review-small {
    font-size: 0.75em;
    font-weight: 800;
    color: white;
    position: absolute;
    margin-top: -3px;
    margin-left: 11px;
}
.p-info.grey {
    color: #8C898A !important;
    padding: 0 12px;
    min-width: fit-content;
}

.faq-container {
    padding-left: 70px;
}
.collapsible-faq-container {
    max-width: 900px;
}
.faq-options {
    cursor: pointer;
}
.faq-question {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 900px;
    justify-content: space-between;
    align-items: center;
}
.faq-question svg {
    fill: #C4C4C4;
}
.faq-question p {
    font-weight: 300;
}

.faq-desc {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.19);
    border-radius: 22px;
    height: 100px;
    width: inherit;
    max-width: 900px;
    padding: 12px;
}

.p-location {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    color: #8C898A;
    cursor: pointer;
}
.provider-row.course-info span {
    color: #29ABE2;
}
.provider-row.course-info {

}
.provider-info-container.course-info {
}
.course-info-container {
    padding-left: 12px;
    padding-top: 12px;
}
.row_inner_right-icons {
    padding-left: 0px;
    padding-top: 12px;

}
.row_inner_right-icons img {
    padding-right: 12px;
}
.row_inner_right-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.follow-heart {
    cursor: pointer;
}

.course-packages-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 60px;
}
.course-package-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.course-package-col .p-title, .course-package-col ul li {
    font-weight: 300;
}
.course-package-col .p-title {
    cursor: pointer;
    margin: 12px 12px;
}
.course-package-col ul li::marker, .upload-section ul li::marker{
    list-style-type: circle;
    color: black;
    font-size: 8px;
}
.course-package-col.needs ul {
    margin-top: 0;
    margin-bottom: 48px;
}

.course-accommodation-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 60px;
    padding-bottom: 60px;
}
.course-accommodation-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.course-accommodation-container .p-text.accommodation {
    padding: 24px;
    padding-left: 60px;
}
.course-accommodation-container .p-location {
    margin-top: 36px;
}
.course-accommodation-container img {
    border-radius: 12px;
}
.course-cloud-container {
    position: absolute;
    right: 56px;
    border-radius: 40px;
    background: #ffffff70;
    top: 550px;
    padding: 0 24px 24px 0px;
    background: linear-gradient(142.24deg, rgba(255, 255, 255, 0.1) 14.23%, rgba(217, 217, 217, 0.4) 83.59%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(18px);
}

.course-cloud-container .course-package-col .p-text {
    cursor: pointer;
}
.course-cloud-container .cloud-row.contact {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    padding-right: 12px;
    cursor: pointer;
}
.course-cloud-container ul {
    padding: 0;
}
.reservation-row.cloud p:not(:first-child) {
    font-weight: 400;
}
.reservations-cloud {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-end;
    padding: 12px;
    padding-right: 18px;
    padding-top: 12px;
}
.comparison-scroll .scroll::-webkit-scrollbar, .reservations-cloud.scroll::-webkit-scrollbar {
    width: 8px;
}

.accommodation-cloud {
    padding: 24px 24px 24px 36px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.space-between {
    justify-content: space-between;
}
.accommodation-cloud .row.inner {
    min-width: 150px;
    justify-content: space-between;
    align-self: flex-end;
    max-width: 270px;
}
.accommodation-cloud .row {
    flex-wrap: wrap;
    width: 100%;
}
.reservation-row.cloud.accommodation {
    flex-direction: column;
}
.reservation-row {
    padding: 4px 0;
}
.reservation-row .row.inner {
    padding: 4px 0;
}
.cloud.row {
    padding-left: 36px;
    padding-top: 24px;
}
.cloud.row .p-title:hover {
    text-decoration: underline;
}

.comparison-section {
    width: 580px;
    min-width: 580px;
    padding: 48px 24px 48px 0px;
    background: #AFD1E88A;
}
.comparison-section:nth-child(1) {
    border-radius: 20px 0px 0px 20px;
    background: #AFD1E8B8;
}
.comparison-section:last-child {
    border-radius: 0px 20px 20px 0px;
    background: #AFD1E8;
}
.comparison-section .reservation-col-date {
    width: 90%;
}
.comparison-section ul {
    align-self: flex-start;
    padding-left: 74px;
}
.col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.center {
    justify-content: center;
}
.comparison-container {
    padding-bottom: 48px;
}
.comparison-legend {
    height: 1000px;
    justify-content: flex-start;
    display: flex;
    padding-right: 60px;
    min-width: max-content;
}
.start {
    justify-content: flex-start;
}
.row-cloud {
    padding: 24px;
}
.comparison-scroll {
    padding-bottom: 28px;
    overflow-x: auto;
}

/* COURSE EDIT PAGE */
.course-edit .p-pageinfo span {
    text-decoration: #29ABE2 underline !important;
}
.course-edit {
    padding-bottom: 60px;
}
.course-edit .select-default {
    margin: 0
}
.course-edit .form-container {
    padding-left: 48px;
}
.course-packages-container.edit .input-label {
    margin-right: -24px;
    padding: 12px 0;
}
.course-packages-container.edit input {
    padding-left: 24px;
}
.course-description .input-label {
    align-items: flex-start;
}
.course-package-col.needs {
    align-items: flex-start;
}
.course-description .custom-button {
    margin-left: 24px;
}
.course-packages-container.lecturer .input-textarea {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.19);
    border-radius: 22px;
    width: 100%;
    height: 100px;
    padding: 12px;
    min-height: auto !important;
}
.circle-button {
    padding-left: 12px;
    padding-bottom: 12px;
    cursor: pointer;
}
.circle-button:hover {
    transform: scale(1.02);
}
.lecturers-item .lecturers-col {
    margin-top: 12px;
}
.accommodation-desc .course-description {
    padding: 0
}
.form-container.accommodation .input-label {
    padding-left: 0;
}

.form-container.accommodation .form-row form > label > p {
    margin-right: 24px;
    min-width: 10%;
}
.edit-comparison .course-package-col ul {
    max-height: 100px;
    min-height: 100px;
    overflow-y: auto;
    width: 80%;
}
.edit-comparison .form-row form > label > input {
    width: fit-content;
}
.edit-comparison .double-row input {
    width: 110px !important;
}
.course-edit.provider-edit .form-row form > label {
    align-items: flex-start;
}

.course-edit-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.course-col.course-col-edit {
    cursor: auto;
}
.custom-button-edit {
    height: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 10px 0;
    margin-bottom: 0;
    width: 120px;
    border: none;
    cursor: pointer;
}
.custom-button-edit:hover {
    transform: scale(1.02);
}
.course-edit-buttons > div:nth-child(1) {
    background: #FCB779;
}
.course-edit-buttons > div:nth-child(2) {
    background: #FF9E42;
}
.course-edit-buttons > div:nth-child(3) {
    background: #FE7E05;
}
.course-col-edit {
    padding-bottom: 40px;
}
.add-course-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    height: 300px;
    background: #FFFFFF;
    box-shadow: 0px 2px 160px rgba(0, 0, 0, 0.08);
    border-radius: 37px;
}
.add-course-card-top {
    height: 40%;
    width: 100%;
    background: #D6DADC;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 37px;
    border-top-right-radius: 37px;
}
.add-course-card-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
}
.add-course-card-bottom img {
    margin-top: -24px;
}
.image-click:hover {
    transform: scale(1.04);
}
.add-course-card {
    margin-top: 48px;
}
.faq-edit {
    padding-bottom: 24px;
}

.faq-edit .input-label {
    padding-left: 24px;
}
.faq-edit form:last-child .input-label {
    padding-left: 40px;
    padding-top: 0;
    margin-top: -12px;
}
.p-faq-numbered {
    height: 0;
    margin-bottom: -28px;
}
div.add {
    cursor: pointer;
}
.custom-dropdown .select-default {
    max-width: 800px;
    width: 520px;
}
.stats-container {
 padding-left: 48px;
}
.stats-container .row:last-child p {
    color: #8C898A;
}
p.underline {
    text-decoration: underline;
}
.stats-container .row {
    padding: 12px 12px;
}


/* UPLOAD AREA */
.upload-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.upload-container {

}
.upload-area-button {
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 41px;
    border: none;
    cursor: pointer;
}
.upload-area-button:hover {
    background: #e3e3e3;
}
.upload-area-button .upload-area-container > * {
    padding: 24px 0;
}
.upload-area {
}
.upload-section ul {
    align-self: flex-start;
}
.upload-section ul li {
    text-align: left;
    font-size: 9pt;
    color: #707070;
    font-style: italic;
    font-weight: 400;
}
.uploaded-gallery {
    overflow-x: auto;
    padding: 24px 0;
}
.uploaded-image-item {
    padding: 12px;
}
.uploaded-image-item img {
    height: 100px;
    object-fit: contain;
}
p.remove:hover {
    color: red;
    cursor: pointer;
}
.image-item__btn-wrapper {
    padding: 0 12px;
}
.provider-edit.tab_presentation .dropdown_row {
    background: transparent;
}
.lecturers-dropdown form label {
    align-items: flex-start;
}
.lecturers-container.edit .course-packages-container.course-description.lecturer  {
    padding-left: 12px;
}

/* LOGO UPLOAD */
.upload-container.logo {

}
.upload-container.logo .upload-section {

}
.upload-container.logo .upload-area-button {
    height: 90px;
    width: 90px;
    margin: 12px;
}
.upload-container.logo  .uploaded-gallery {

}
.upload-container.logo .upload-area-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.upload-container.logo .upload-area-container > * {
    padding: 0;
}

.upload-container.logo .uploaded-gallery {
    padding: 0;
}
.upload-container.logo .uploaded-image-item {
    padding: 0 12px;
    padding-top: 2px;
}
.upload-container.logo .uploaded-gallery img {
    border-radius: 45px;
    height: 90px;
    width: 90px;
    object-fit: cover;
}


/* DATE & HOUR RANGE PICKER */
.date-hour-container {
    padding: 4px 0;
}
.date-edit-container {

}

.date-picker, .hour-range-picker {
    font-size: 14px;
}
.react-date-picker__wrapper, .react-timerange-picker__wrapper {
    border: none !important;
}
.react-date-picker__button svg, .react-timerange-picker__button svg {
    stroke: #5a5a5a !important;
}
.react-timerange-picker__button:hover svg, .react-date-picker__calendar-button.react-date-picker__button:hover svg {
    stroke: #29ABE2 !important;
}
.react-date-picker__clear-button.react-date-picker__button:hover svg, .react-timerange-picker__clear-button.react-timerange-picker__button:hover svg {
    stroke: red !important;
}
.react-timerange-picker__inputGroup__hour, .react-timerange-picker__inputGroup__minute, .react-date-picker__inputGroup {
    background: white !important;
    border-radius: 4px;
    min-width: 17px !important;
}
.react-calendar__tile.react-calendar__month-view__days__day {
    padding: 6px 0px;
}
.react-calendar__tile.react-calendar__month-view__days__day {
    font-size: 12px;
}
.react-calendar__navigation {
    margin: 0 !important;
}
.button-label {
    background: #F99537;
    border-radius: 5px;
    padding: 8px 24px;
    color: #FFFFFF;
}
.strikethrough {
    text-decoration: line-through;
    text-decoration-color: red;
    opacity: 0.68;
}


input[type="checkbox"] {
    width: 20px;
    height: 20px;

    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    color: #fff;
    margin: 0;

    font: inherit;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
}
input[type="checkbox"]::before {
    content: "";
    width: 12px;
    height: 12px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #F99537;

    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.comparison-section .checkbox-accommodation, .accommodation-cloud .checkbox-accommodation {
    margin-left: -36px;
    margin-top: 4px;
}

.checkbox-start-date {
    margin-left: -36px;
}
.checkbox-start-date input[type="checkbox"]{
    height: 26px;
    width: 22px;
    margin-bottom: -28px;
}

/* DYNAMIC LISTS*/

.item-list.dynamic {
    position: relative;
    right: 0;
    overflow: scroll;
    max-height: 400px;
    padding-left: 16px;
}

.modal .item-list.dynamic {
    max-height: 150px;
    height: 150px;
}

.modal-auto-height .modal-body {
    max-height: fit-content !important;
}
.dashed-border-title {
    border-bottom: 1px dashed #333;
    padding-bottom: 6px;
    margin-bottom: 6px;
    width: fit-content;
}

.dynamic-header > div{
    font-weight: 600;
    padding-top: 6px;
    padding-bottom: 16px;
    font-family: 'Open Sans', sans-serif;
}
.dynamic-header {
    display: grid;

    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(48px, auto);
    grid-gap: 8px;
    list-style: none;
}

ul.item-list.dynamic {
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: auto;
}

ul.item-list.dynamic li {
    margin: 4px 0;
    padding: 2px 10px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(36px, auto);
    grid-gap: 12px;
    list-style: none;
    border-radius: 2px;
    font-size: 1em;
    font-weight: 300;
}
ul.item-list.dynamic li:nth-child(even) {
    background: #f5f5f5;
}
ul.item-list.dynamic li:nth-child(odd) {
    background: #ffffff;
}

.dynamic.container-column-2 {
    flex-wrap: wrap;
}
.dynamic.container-column-2 .column-2 {
    width: auto;
}
.dynamic.container-column-2 > .column-2:first-child {
    padding-bottom: 80px;
    padding-right: 24px;
}
.dynamic.container-column-2 > .column-2:last-child {
    padding-left: 0px;
}

.list-container {

}
.page-info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* ADMIN CONTROL PANEL */
.admin-category-edit {
    margin-top: -36px;
    padding-bottom: 36px;
}

.admin-category-edit .add-course-card{
    margin-right: 24px;
}
.admin-panel .categories_container {
    pointer-events: none;
}
.admin-category .custom-button {
    margin-left: 24px;
    margin-top: 12px;
}
.admin-panel .add-course-card .p-info {
    opacity: 0;
    transition: all 0.5s;
}
.admin-panel .add-course-card:hover .p-info {
    opacity: 1;
    transition: all 0.5s;
}
.admin-panel .add-course-card.selected .p-info {
    opacity: 1;
}
.admin-panel .add-course-card {
    border: 4px solid #F8F8F8;;
}
.admin-panel .add-course-card.selected {
    border: 4px solid #eeeeee;
}
#sqm_form_16 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    bottom: 70px;
}

/* MOBILE */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .stats-container, .course-edit .form-container, .faq-container, .course-packages-container, .course-accommodation-container {
        padding-left: 0;
    }

    .logout {
        margin-right: 24px;
    }

    .lecturers-container, .lecturers-container .lecturer.item {
        flex-wrap: wrap;
    }

    .faq-edit form:last-child .input-label {
        padding-left: 24px;
    }

    .faq-edit .input-label input {
        max-width: 50%;
    }

    .lecturers-item {
        flex-wrap: inherit;
    }

    .dropdown_full_width {
        flex-wrap: wrap;
    }

    .input-label input, .input-label textarea {
        min-width: 120px;
    }
    .dropdown_content {
        left: 12px;
    }

    .header .header_inner_right {
        flex-wrap: wrap;
        position: absolute;
        right: 0;
        height: 159px;
        background-image: url("../src/assets/images/header.png");
        background-position-x: 650px;
        object-fit: contain;
        width: 160px;
        top: 0;
        border-bottom-left-radius: 12px;
        border-top-left-radius: 12px;
        padding-bottom: 12px;
        padding-top: 12px;
        zoom: 0.9;
    }
    .header .header_inner_right img {
        padding-top: 12px;
        zoom: 0.75;
        padding-left: 6px;
    }
    .header .header_inner_right p.p-submit:first-child {
        margin-bottom: 12px;
        zoom: 0.9;
        padding: 0px 12px;
    }
    .header .header_logo {
        max-width: 30%;
    }
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and down) */
@media only screen and (max-width: 992px) {
    .course-cloud-container {
        position: relative;
        margin: 48px 0;
        top: 0;
        margin-left: 30px;
        width: 100%;
    }

    .lecturers-container {
        flex-wrap: wrap;
    }

    .form-container.accommodation {
        max-width: fit-content;
    }

    .accommodation-desc .course-description textarea, .course-packages-container.course-description textarea {
        max-width: fit-content;
    }

    .accommodation .form_col.form-row .row, .course-packages-container.edit, .course-packages-container.course-description {
        flex-wrap: wrap;
    }

    .course-packages-container.edit {
    }

    .form-container.accommodation form {
        width: 100%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and down) */
@media only screen and (max-width: 1200px) {
    .lecturers-container {
        flex-wrap: wrap;
    }

    .course-cloud-container {
        margin-top: 48px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}