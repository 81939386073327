.header_container.large {
    height: 70vh;
}
.header_container.large .header {
    align-items: flex-start;
}
.header_container.large .header .header_inner_right/*, .footer_inner_right */{
    padding-top: 56px;
}
.header_landing {
    position: absolute;
    top: 200px;
    left: 156px;
    width: 420px;
}


/* MOBILE */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .header_landing {
        top: 220px;
        left: 66px;
        width: auto;
    }
    .header_landing .p-landing {
        font-size: 1.75em;
    }
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {
    .header_landing {
        width: auto;
    }
}

/* Large devices (laptops/desktops, 992px and down) */
@media only screen and (max-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and down) */
@media only screen and (max-width: 1200px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}