.title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-bottom: 1em;
    margin-top: 1em;
}

.dot {
    margin-top: 2px;
    width: 12px;
    height: 11px;
    margin-right: 6px;
    background-color: #F99537;
    border-radius: 50%;
    display: inline-block;
}
.dot.space {
    margin-right: 22px;
}

.p-title {
    color: black;
    font-size: 1.5em;
}