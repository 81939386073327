.custom-button {
    background-color: #29ABE2;
    color: white;
    height: 25px;
    border-radius: 5px;
    cursor: pointer;
    width: fit-content;
    padding: 2px 8px 2px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-button:hover {
    font-weight: 600;
    letter-spacing: -0.013rem;
}