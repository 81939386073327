.footer_page_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    background-color: #D6DADC;
    padding-bottom: 24px;
}
.footer_col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 56px;
}
.social_row {
    padding-top: 10px;
}
.social_row img {
    cursor: pointer;
}
.footer_col .p-title {
    font-weight: 300;
}
.footer_page_container .p-title {
    margin-bottom: 10px;
}
.footer_page_container .p-desc {
    margin-bottom: 10px;
}
.footer_page_container a {
    color: #1B1C1D;
    text-decoration: none;
}
.copyright_row {
    background-color: #1B1C1D;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.copyright_row.left {
  pointer-events: none;
}
.copyright_row.left {
    padding-left: 12px;
}
.p-copyright {
    color: white;
    font-size: 0.78em;
    font-weight: 300;
}
.copyright_row img {
    object-fit: contain;
}
.copyright_row p {
    padding: 0 4px;
    letter-spacing: 0.3px;
}
.copyright_row.right p {
    padding: 0 24px;
}
.copyright_row p:not(.delimiter):hover {
    cursor: pointer;
    font-weight: 400 !important;
    letter-spacing: unset;
}
