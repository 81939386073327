.dropdown_full_width {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px;
    padding-right: 0;
    padding-top: 4px;
    align-items: center;
}
.dropdown_full_width > .p-label {
    min-width: 10vw;
}
.dropdown_circle {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 12px;
}
.dropdown_circle:hover img {
    cursor: pointer;
}
.dropdown_row {
    cursor: pointer;
    background: white;
}
.dropdown_content {
    padding-top: 12px;
    padding-right: 30px;
    position: absolute;
    margin-top: 30px;
    margin-right: 16px;
}
.dropdown_content > .dropdown_row:hover p {
    border-bottom: 2px solid #29ABE2;
}
.dropdown_content > .dropdown_row p {
    border-bottom: 2px solid transparent;
    margin: 8px;
}
.dropdown_content.hidden {
    display: none;
}
.dropdown_row .p-label {

}
.dropdown_selection {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 538px;
}
.dropdown_selection .dropdown_col {
    padding-right: 8px;
}
.dropdown_col, .dropdown_rpw {
    cursor: pointer;
}
.dropdown_close {
    margin-top: -8px;
    position: absolute;
    font-size: 0.7em;
}
