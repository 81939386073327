.select-default {
    width: 100%;
    height: 36px;
    border-radius: 5px;
    font-size: 15px;
    color: #707070;
    margin-bottom: 70px;
}
.select-default option {
    font-size: 16px;
    padding-right: 10px;
    padding-left: 5px;
}
.select-default__control {
    height: 36px;
}
.select-default__control > div {
    height: 100%;
}
.select-default__control, .select-default__control:hover {
    border: none !important;
    box-shadow: none !important;
}
.select-default__option--is-selected, .select-default__option--is-focused.select-default__option--is-selected {
    border: none;
    background-color: #F99537 !important;
}
.select-default__option--is-focused {
    background-color: rgba(0, 137, 65, 0.08) !important;
}

.grey .select-default__control {
    background-color: #F0F0F0;
}
.select-default.invalid-input .select-default__control {
    border: 1px solid red !important;
}
.select-default__input-container {
    margin-top: -8px !important;
}
.select-default__value-container, .select-default__indicator {
    background-color: #FEFEFE4D;
}



